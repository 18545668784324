const btnStyle = {
    buttonNext: {
        backgroundColor: 'black',
        border: 0,
        borderRadius: 0,
        color: '#fff',
        cursor: 'pointer',
        fontSize: 16,
        lineHeight: 1,
        padding: 8,
        WebkitAppearance: 'none'
    },
    buttonBack: {
        color: 'black',
        marginLeft: 'auto',
        marginRight: 5
    }
}

export const tourSteps = [
    {
        target: '#anonymised-privacy',
        title: 'Introduction',
        styles: btnStyle,
        placement: 'center',
        content: 'Welcome! On this website, you have the ability to view and manage the data collected by Anonymised.',
        disableBeacon: true
    },
    {
        target: '#anonymised-privacy-link',
        title: 'Privacy Policy',
        styles: btnStyle,
        placement: 'auto',
        content: 'For additional information about our privacy policy, please refer to this link.',
        disableBeacon: true
    },
    {
        target: '#user-account',
        title: 'Sub Id',
        styles: btnStyle,
        content: 'Here you can see your unique sub id and when it was created ',
        disableBeacon: true
    },
    {
        target: '#user-data-summary',
        title: 'Data Summary',
        styles: btnStyle,
        placement: 'auto',
        content: 'Here`s the summary of collected data, which is divided into 3 sections.',
        disableBeacon: true
    },
    {
        target: '#device-button',
        title: 'Data Summary',
        styles: btnStyle,
        placement: 'auto',
        content: 'You can click on them to examine all values',
        disableBeacon: true
    },
    {
        target: '.dashboard-data',
        title: 'Your Device',
        styles: btnStyle,
        placement: 'auto',
        content: 'This section encompasses data related to the characteristics of device used, browser & operational system',
        disableBeacon: true
    },
    {
        target: '.dashboard-data',
        title: 'Your Interests',
        styles: btnStyle,
        placement: 'auto',
        content:
            'The `Interests` section involves data that reflects the preferences, hobbies, and topics of interest for users. It might include information about the content they engage with, products they frequently explore, or activities they enjoy. Understanding user interests helps in personalizing content and recommendations to enhance the overall user experience.',
        disableBeacon: true
    },
    {
        target: '.dashboard-data',
        title: 'Your Topics',
        styles: btnStyle,
        placement: 'auto',
        content:
            'The `Topics` section involves data that reflects the preferences, hobbies, and topics of interest for users. It might include information about the content they engage with, products they frequently explore, or activities they enjoy. Understanding user interests helps in personalizing content and recommendations to enhance the overall user experience.',
        disableBeacon: true
    },
    {
        target: '#user-data-websites',
        title: 'Visited Websites',
        styles: btnStyle,
        placement: 'auto',
        content: 'Websites which were visited by you and have Anonymised analytics will be displayed here',
        disableBeacon: true
    },
    {
        target: '#user-data-download',
        title: 'Download Data',
        styles: btnStyle,
        placement: 'auto',
        content: 'Data colllected about you can be downloaded as .csv file',
        disableBeacon: true
    },
    {
        target: '#user-data-delete',
        title: 'Delete Data',
        styles: btnStyle,
        placement: 'auto',
        content: 'You can always delete ALL records about you',
        disableBeacon: true
    },
    {
        target: '#insights-title',
        title: 'Data Update',
        styles: btnStyle,
        placement: 'auto',
        content:
            'Your data is updated in real time. Please visit one of the websites using the Anonymised technology, accept cookies and refresh this tab to see the data that has been collected',
        disableBeacon: true
    }
]
