import React, { Suspense, lazy, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Loader from './components/Loader'
import Joyride, { CallBackProps, StepProps } from 'react-joyride'
import { LocalStorageKeys } from './types/LocalStorageKeys'
import { tourSteps } from './utils/tourSteps'

const App = lazy(() => import('./App'))

const joyrideOptions = {
    locale: {
        close: 'Next',
        last: 'Finish'
    }
}

const AppWrapper = () => {
    const [joyrideSteps, setJoyrideSteps] = useState([])
    const [joyrideHistory, setJoyrideHistory] = useState<CallBackProps>(null)
    const [enabledOverlay, setEnabledOverlay] = useState(true)
    const [runTour, setRunTour] = useState(false)

    const tour = {
        steps: joyrideSteps,
        history: joyrideHistory,
        enabledOverlay: enabledOverlay,
        isRunning: runTour,
        run: (value: boolean | ((prevState: boolean) => boolean)) => {
            setRunTour(value)
        },
        action: (steps: StepProps[]) => {
            setJoyrideSteps(steps)
        },
        enableOverlay: (value: boolean | ((prevState: boolean) => boolean)) => {
            setEnabledOverlay(value)
        }
    }

    const handleJoyrideCallback = (data: CallBackProps) => {
        setJoyrideHistory(data)
        if (data.action === 'close' || data.action === 'skip' || data.action === 'reset') {
            setRunTour(false)
            localStorage.setItem(LocalStorageKeys.Tour, 'true')
        }
    }

    useEffect(() => {
        tour.action(tourSteps as any)
    }, [])

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader absolutePosition={true} />}>
                <App tour={tour} />
                {joyrideSteps?.length > 0 && (
                    <Joyride
                        steps={joyrideSteps}
                        scrollToFirstStep
                        scrollDuration={450}
                        locale={joyrideOptions.locale}
                        continuous
                        disableOverlay={!enabledOverlay}
                        callback={handleJoyrideCallback}
                        run={runTour}
                        showProgress
                        showSkipButton
                        hideBackButton
                        scrollOffset={20}
                    />
                )}
            </Suspense>
        </BrowserRouter>
    )
}

createRoot(document.getElementById('root')).render(<AppWrapper />)
